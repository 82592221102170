<template>
  <v-main>
    <span class="bg"></span>
    <NotificationComp />
    <!--  <v-parallax src="https://cdn.vuetifyjs.com/images/parallax/material.jpg"> -->
    <v-container class="fill-height" fluid>
      <v-row align="center" justify="center">
        <v-col cols="12" sm="8" md="4" lg="3">
          <v-scale-transition>
            <v-card class="elevation-12" v-if="!isLoading">
              <v-toolbar color="indigo" dark flat>
                <v-toolbar-title>meTesteo - {{ $t("login") }}</v-toolbar-title>
                <v-spacer />
              </v-toolbar>
              <v-card-text>
                <v-form>
                  <v-text-field
                    v-model="email"
                    label="email"
                    name="email"
                    prepend-icon="mdi-email"
                    type="email"
                    autocomplete="off"
                    clearable
                  />

                  <v-text-field
                    v-model="pass"
                    id="password"
                    label="Contraseña"
                    name="password"
                    prepend-icon="mdi-lock"
                    type="password"
                    clearable
                  />
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn color="indigo" dark @click.prevent="loginUser">{{
                  $t("access")
                }}</v-btn>
              </v-card-actions>
            </v-card>
          </v-scale-transition>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import userMixin from "@/_mixin/userMixin";
import NotificationComp from "@/components/common/NotificationComp";

export default {
  name: "login",
  mixins: [userMixin],
  components: {
    NotificationComp,
  },
  data: () => ({
    isLoading: true,
    email: null,
    pass: null,
  }),
  methods: {
    async loginUser() {
      const username = this.email || null;
      const password = this.pass || null;
      if (!username || !password) {
        const text = this.$t("no-field-completed");
        const type = "error";
        this.mixSetSnackbar(text, type);
        return;
      }
      try {
        const res = await this.mixSetLoginUser(username, password);

        if (res.statusText === "OK") {
          setTimeout(() => {
            this.$router.push({ name: "Home" });
          }, 800);
        }
        if (res === false) {
          const text = this.$t("error-access-data");
          const type = "error";
          this.mixSetSnackbar(text, type);
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
  computed: {},
  watch: {},
  mounted() {
    this.isLoading = false;
  },
};
</script>

<style lang="scss" scoped></style>
